html {
    font-size: 17px;
}

@font-face {
    font-family: Swiss721;
    font-style: normal;
    font-display: swap;
    font-weight: 300;
    src: url('./assets/fonts/Swiss721Light.woff') format('woff');
}

@font-face {
    font-family: Swiss721;
    font-style: normal;
    font-display: swap;
    font-weight: 600;
    src: url('./assets/fonts/Swiss721Medium.woff') format('woff');
}

@font-face {
    font-family: Swiss721;
    font-style: italic;
    font-display: swap;
    font-weight: 400;
    src: url('./assets/fonts/Swiss721Italic.woff') format('woff');
}

@font-face {
    font-family: Swiss721;
    font-style: normal;
    font-display: swap;
    font-weight: 800;
    src: url('./assets/fonts/Swiss721Heavy.woff') format('woff');
}

